@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary: #393b40;
  --white: #ffffff;
  --lightGray: #ecf0f1;
  --orange: #F68D28;
  --blue: #239AD6;
  --amplify-primary-color: #393b40;
  --amplify-primary-tint: #c2c7cf;
  --amplify-primary-shade: #393b40;
}

@import "~antd/dist/antd.less";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f5f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.ant-menu-item-selected,
.ant-select-item-option-active,
.ant-select-item-option-selected {
  background-color: #f4f5f7 !important;
}

.ant-table-row {
  cursor: pointer;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  min-height: 62px;
}

.report-style-class {
  height: 100%;
  overflow: hidden;
}

.group-logo{
  height: 50px;
}

.header {
  img {
    max-height: 32px;
  }
  button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

iframe {
  border: 0;
}

// .ant-picker,
// .ant-picker-focused,
// .ant-select-selector {
//   background-color: var(--lightGray) !important;
// }

@primary-color: #393B40;@border-radius-base: 5px;